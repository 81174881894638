import React from "react";
import BlockTitle from "./BlockTitle";

import CTAImage1 from "../assets/images/resources/cta-2-moc-1.png";
import CTAImage2 from "../assets/images/resources/cta-2-moc-2.png";
import CTAImage3 from "../assets/images/resources/cta-2-moc-3.png";

const CTATwo = () => {
  return (
    <section className="cta-two">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-two__content">
              <BlockTitle
                textAlign="left"
                paraText="Avante Agro"
                titleText={`Feito para o produtor\n rural brasileiro`}
              />
              <div className="cta-two__icon-wrap">
                <div className="cta-two__icon-single">
                  <div className="cta-two__icon">
                    <i className="apton-icon-app-development"></i>
                  </div>
                  <h3>
                    NFPe
                  </h3>
                </div>
                <div className="cta-two__icon-single">
                  <div className="cta-two__icon">
                    <i className="apton-icon-app-development"></i>
                  </div>
                  <h3>
                    MDFe
                  </h3>
                </div>
                <div className="cta-two__icon-single">
                  <div className="cta-two__icon">
                    <i className="apton-icon-app-development"></i>
                  </div>
                  <h3>
                    CTe
                  </h3>
                </div>
              </div>
              <div className="cta-two__text">
                <p>                  
                  O Avante Agro é o jeito simples de fazer a gestão da sua safra e produção. 
                  Emita documentos fiscais, como a NFP-e, diretamente do navegador, 
                  sem necessidade de instalar de software. 
                  Integrado com a sua contabilidade. 
                  Gere livro-caixa de forma instantânea, sem papelada.
                </p>
              </div>
              <a href="https://api.whatsapp.com/send?phone=5566996866082&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20sistema" target='_blank' className="thm-btn cta-two__btn">
                <span>Saiba Mais</span>
              </a>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="cta-two__images d-flex justify-content-end align-items-end flex-column">
              <img
                src={CTAImage1}
                className="wow fadeInUp"
                data-wow-duration="1500ms"
                alt="Avante Agro"
              />            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTATwo;
