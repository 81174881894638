import React from "react";
import { Container } from "react-bootstrap";

import Cta3Shape1 from "../assets/images/shapes/cta-three-bg-1-1.png";
import Cta3Shape2 from "../assets/images/shapes/cta-three-bg-1-2.png";

const CTAThree = () => {
  return (
    <section className="cta-three">
      <img src={Cta3Shape1} className="cta-three__bg-1" alt="awesome post" />
      <img src={Cta3Shape2} className="cta-three__bg-2" alt="awesome post" />
      <Container className="text-center">
        <h3>
        Tem uma ideia genial<br/> e precisa de ajuda<br/> para colocá-la no mercado?
        </h3>
        <p>conte com a gente temos a experiencia que você precisa.</p>        
      </Container>
    </section>
  );
};

export default CTAThree;
